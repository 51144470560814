<template>
    <page-layout>
        <v-container class="fill-height d-flex">
            <v-row no-gutters class="px-6">
                <v-col cols="12" class="nolo-h3 text-center font-weight-black">{{ $t('wip.heading') }}</v-col>
                <v-col cols="12" class="nolo-p1 mt-6 text-center">{{ $t('wip.message') }}</v-col>
                <v-col cols="12" class="mt-4 text-center">{{ $t('wip.signed') }}</v-col>
            </v-row>
        </v-container>
    </page-layout>
</template>

<script>
    export default {
        name: "NotFound",
        computed: {
            version() {
                return process.env.VERSION;
            }
        }
    }
</script>

<style scoped>
    .version {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
</style>

